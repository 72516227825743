.slider{
    position: relative !important;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    background: black;
    i{
        font-size: 2vw;
    }
}
.slide{
    position: relative !important;
    min-width: 100%;
    height: 80%;
    transition: 0.5s;
    overflow: hidden;
}
%btn-styles{
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%) !important;
    width: 10%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
    transition: 0.5s;
    &:hover{
        background: rgba(0, 0, 0, 0.356);
        cursor: pointer;
        i{
            color: whitesmoke;
        }
    }
}
#goLeft{
    left: 0;
    @extend %btn-styles;
}
#goRight{
    right: 0;
    @extend %btn-styles;
}