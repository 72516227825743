.project-container{
    //padding: 1rem 40vw;
    padding: 1rem 29vw;
    background-color: black;
}
.project-slide {
    //min-width: 21vw !important;
    //max-width: 25vw !important;
    min-width: 41vw !important;
    max-width: 45vw !important;
    overflow: visible !important;
    pointer-events: none;
  }
.project-col{
    
    position: relative !important;
    //min-width: 25vw;
    min-width: 45vw;
    height: 50vw;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: table-cell;
    align-items: center;
    overflow: hidden;
}
%box{
    display: block;
    background-color: whitesmoke;
    //border: 20vw;
    border: 40vw;
    position: relative !important;
    margin: 5px;
    min-width: 40vw;
    min-height: 40vw;
    max-width: 45vw;
    max-height: 45vw;
    //min-width: 20vw;
    //min-height: 20vw;
    //max-width: 25vw;
    //max-height: 25vw;
    //transition: 0.5s;
    overflow: hidden;
    &:hover{
        top: 0.5vw;
        margin: 0px;
        min-width: 40vw;
        min-height: 40vw;
        max-width: 40vw;
        max-height: 40vw;
        border: 40vww;
        border: 0.3vw solid whitesmoke;
    }
}

.boxUp {
    -moz-transform: skew(25deg, 0deg);
    -webkit-transform: skew(25deg, 0deg);
    -o-transform: skew(25deg, 0deg);
    -ms-transform: skew(25deg, 0deg);
    transform: skew(25deg, 0deg);
    @extend %box;
}
.boxDown {
    -moz-transform: skew(-25deg, 0deg);
    -webkit-transform: skew(-25deg, 0deg);
    -o-transform: skew(-25deg, 0deg);
    -ms-transform: skew(-25deg, 0deg);
    transform: skew(-25deg, 0deg);
    @extend %box;
}
%img{
    width: 60vw !important;
    height: 40vw !important;
    //width: 30vw !important;
    //height: 20vw !important;
    object-fit: cover;
    height: -webkit-fill-available;
    transform-origin: top;
}
.img-boxUp{
    -moz-transform: skew(-25deg, 0deg);
    -webkit-transform: skew(-25deg, 0deg);
    -o-transform: skew(-25deg, 0deg);
    -ms-transform: skew(-25deg, 0deg);
    transform: skew(-25deg, 0deg);
    transform-origin: top;
    @extend %img;
}
.img-boxDown{
    -moz-transform: skew(25deg, 0deg);
    -webkit-transform: skew(25deg, 0deg);
    -o-transform: skew(25deg, 0deg);
    -ms-transform: skew(25deg, 0deg);
    transform: skew(25deg, 0deg);
    transform-origin: bottom;
    @extend %img;
}
.text{
    position: absolute;
    z-index: 99;
    pointer-events: all;
    bottom: -20px;
    right: 0px;
    background-color: whitesmoke;
    padding: .7rem .5rem;
    border-radius: 25px 0px 0px;
}