.bg-dark{
    background-color: rgba(0, 0, 0, .85) !important;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    min-height: 60px;
    padding: 0rem 1rem;
}
.navbar-brand{
    height: -webkit-fill-available;
    width: 15rem;
    margin-right: 10rem;
}
li > a {
    text-decoration: none;
    color: #f9f9f9 !important;
    padding: 25px 10px 28px 10px !important;
}
.active {
    text-decoration: none;
    color: #000000 !important;
    background: rgb(241, 196, 15) none repeat scroll left top;

}
.active > a {
    color: #000000 !important;
}